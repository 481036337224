import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../layouts/AuthGuard/Authguard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Login />,
    },
    { path: '/register', element: <Signup /> },
  
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/dashboard" replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'user', element: <Professional /> },
        { path: 'user/:id', element: <ProfessionalAdd /> },
        { path: 'assignuser', element: <Assign /> },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/four" replace />, index: true },
        //     { path: 'four', element: <PageFour /> },
        //     { path: 'five', element: <PageFive /> },
        //     { path: 'six', element: <PageSix /> },
        //   ],
        // },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Professional = Loadable(lazy(() => import('../pages/Professional')));
const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const Signup = Loadable(lazy(() => import('../pages/Auth/Signup')));
const ProfessionalAdd = Loadable(lazy(() => import('../pages/ProfessionalAdd')));
// const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
const Assign = Loadable(lazy(() => import('../pages/Category')));
// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
