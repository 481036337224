import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { dispatch } from '../redux/store';
import { Logins } from '../redux/Auth/LoginSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const [showPassword, setShowPassword] = useState(false);
  // const Naviagte = useNavigate()

  const [Data, setdata] = useState({
    email: '',
    password: '',
  });
  const handlechange = (event) => {
    const { name, value } = event.target;
    setdata((prevData) => ({
      ...prevData,

      [name]: value,
    }));
  };

  const onSubmit = async () => {
    dispatch(Logins(Data));
  };

  return (
    <>
      <Stack spacing={3}>
        <Toaster position="top-right" reverseOrder={false} />
        <TextField label="Email" variant="outlined" fullWidth value={Data.email} name="email" onChange={handlechange} />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          name="password"
          type="password"
          value={Data.password}
          onChange={handlechange}
        />
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: 'text.secondary' }}>Don't have acount</Typography>
          <Link component={RouterLink} to="/register">
            Sign up
          </Link>
        </Box>
        <Button size="large" onClick={onSubmit} variant="contained">
          Login
        </Button>
      </Stack>
    </>
  );
}
