import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx }}>
    {/* <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="500.000000pt" height="500.000000pt" viewBox="0 0 500.000000 500.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M3221 4156 c-18 -29 -27 -35 -43 -30 -18 5 -19 3 -14 -25 3 -17 9
-44 12 -61 7 -36 -5 -38 -36 -6 -19 21 -25 23 -36 12 -7 -8 -24 -11 -40 -8
-26 5 -27 4 -21 -28 4 -18 2 -38 -3 -45 -8 -10 2 -23 39 -51 30 -23 50 -47 51
-59 0 -19 7 -20 113 -20 100 -1 113 1 110 16 -3 10 14 31 47 59 38 32 48 45
40 55 -7 9 -7 21 0 39 14 37 14 36 -24 36 -19 0 -37 6 -39 13 -3 8 -14 2 -30
-16 -37 -41 -48 -28 -32 38 12 55 12 55 -11 49 -19 -5 -26 1 -42 31 l-19 38
-22 -37z"/>
<path d="M2585 4151 c-64 -27 -81 -110 -32 -158 20 -21 37 -28 67 -28 116 0
130 170 15 190 -16 2 -39 1 -50 -4z"/>
<path d="M2348 3921 c-53 -17 -104 -53 -131 -92 -16 -21 -26 -39 -24 -39 3 0
29 11 59 25 59 27 164 42 187 27 11 -8 10 -19 -3 -68 -54 -189 -157 -394 -276
-546 -32 -41 -17 -34 58 26 154 122 355 366 416 502 l18 42 112 4 c100 3 118
7 179 35 37 18 67 34 67 36 0 2 -46 2 -103 -1 -84 -4 -129 0 -240 22 -152 30
-278 40 -319 27z"/>
<path d="M3386 3653 c52 -126 23 -226 -100 -348 -219 -216 -713 -432 -1181
-515 -97 -18 -335 -48 -390 -51 -34 -1 70 -26 163 -39 48 -6 101 -13 119 -16
23 -3 40 -16 59 -42 40 -54 123 -122 195 -159 35 -18 68 -33 72 -33 4 0 -6 21
-23 46 -32 46 -90 165 -90 184 0 5 16 10 35 10 32 0 37 -4 64 -58 16 -32 50
-81 75 -109 45 -50 131 -113 154 -113 9 0 12 47 12 170 0 129 3 170 13 170 6
0 22 3 35 6 l22 6 0 -176 c0 -116 4 -176 10 -176 22 0 116 68 157 113 52 56
99 147 117 228 l15 61 -37 -6 c-20 -4 -64 -9 -97 -12 l-60 -5 135 57 135 58
133 4 c152 4 141 -4 162 114 10 58 17 68 74 128 80 83 115 129 160 211 55 100
47 181 -25 263 -37 42 -102 86 -127 86 -6 0 1 -26 14 -57z"/>
<path d="M2591 3635 c-17 -30 -31 -57 -31 -60 0 -3 21 -5 46 -5 62 0 84 -14
84 -51 0 -17 -5 -40 -11 -52 -5 -12 13 2 40 31 58 61 82 121 60 148 -20 25
-72 44 -118 44 -37 0 -41 -3 -70 -55z"/>
<path d="M2940 3299 c0 -160 3 -199 13 -192 6 6 51 36 100 67 l87 56 0 135 0
135 -100 0 -100 0 0 -201z"/>
<path d="M2631 3313 c1 -49 4 -145 8 -214 l6 -127 95 38 95 38 3 176 2 176
-105 0 -105 0 1 -87z"/>
<path d="M2325 3053 c4 -98 9 -180 11 -182 2 -2 49 10 104 28 l100 32 0 149 0
150 -111 0 -111 0 7 -177z"/>
<path d="M2025 2948 c4 -73 8 -134 9 -135 2 -2 84 9 164 22 l32 6 0 119 0 120
-106 0 -106 0 7 -132z"/>
<path d="M2980 2748 c-24 -84 -85 -219 -122 -271 l-21 -29 23 7 c48 15 143 79
206 137 64 60 164 197 164 224 0 11 -22 14 -113 14 l-113 0 -24 -82z"/>
<path d="M3485 2292 c-49 -23 -65 -55 -65 -128 0 -75 17 -101 99 -159 73 -51
85 -70 79 -121 -2 -21 -9 -30 -25 -32 -22 -3 -23 0 -23 52 l0 56 -65 0 -65 0
0 -37 c0 -66 19 -112 52 -133 68 -41 169 -37 222 10 30 27 31 31 31 108 0 97
-11 116 -106 181 -35 24 -68 53 -72 64 -12 32 0 67 23 67 17 0 20 -7 20 -40
l0 -40 65 0 c64 0 65 0 65 28 0 55 -20 101 -50 117 -49 26 -139 29 -185 7z"/>
<path d="M1420 2245 l0 -55 40 0 40 0 0 -210 0 -210 65 0 65 0 0 210 0 210 40
0 40 0 0 55 0 55 -145 0 -145 0 0 -55z"/>
<path d="M1790 2298 c0 -2 -16 -118 -35 -258 -19 -140 -35 -258 -35 -262 0 -5
32 -8 70 -8 l70 0 0 50 c0 48 1 50 29 50 31 0 41 -18 41 -72 0 -28 1 -28 65
-28 36 0 65 2 65 5 0 10 -70 471 -76 498 l-6 27 -94 0 c-52 0 -94 -1 -94 -2z
m124 -305 c4 -31 3 -33 -25 -33 -16 0 -29 4 -29 8 0 24 22 179 27 192 4 8 10
-19 14 -60 5 -41 10 -90 13 -107z"/>
<path d="M2120 2035 l0 -265 110 0 110 0 0 55 0 55 -45 0 -45 0 0 210 0 210
-65 0 -65 0 0 -265z"/>
<path d="M2400 2035 l0 -265 118 0 117 0 -3 55 -4 55 -49 0 -49 0 0 55 0 55
45 0 45 0 0 50 0 50 -45 0 -45 0 0 50 0 50 50 0 50 0 0 55 0 55 -115 0 -115 0
0 -265z"/>
<path d="M2710 2035 l0 -265 55 0 55 0 0 118 c0 67 4 111 9 102 4 -8 21 -61
37 -117 l29 -103 58 0 57 0 0 265 0 265 -55 0 -55 0 -1 -117 -1 -118 -39 115
c-21 63 -39 116 -39 118 0 1 -25 2 -55 2 l-55 0 0 -265z"/>
<path d="M3080 2245 l0 -55 40 0 40 0 0 -210 0 -210 65 0 65 0 0 210 0 210 40
0 40 0 0 55 0 55 -145 0 -145 0 0 -55z"/>
<path d="M2721 1689 c-49 -15 -78 -36 -103 -78 -22 -34 -23 -45 -23 -252 l0
-215 27 -41 c36 -54 86 -77 168 -77 76 0 130 22 161 66 30 41 42 155 36 331
-6 173 -18 208 -85 245 -48 27 -131 36 -181 21z m97 -309 c3 -234 1 -253 -31
-248 -22 3 -22 5 -25 218 -1 118 0 221 3 228 3 7 15 12 27 10 23 -3 23 -4 26
-208z"/>
<path d="M790 1360 l0 -332 153 4 c178 4 209 15 227 76 7 24 10 118 8 264 -3
211 -4 228 -24 255 -34 46 -65 55 -220 61 l-144 5 0 -333z m221 185 c9 -41 9
-311 1 -360 -6 -34 -11 -40 -35 -43 l-27 -3 0 221 0 221 27 -3 c22 -2 29 -10
34 -33z"/>
<path d="M1283 1414 c6 -258 7 -277 28 -310 32 -53 90 -79 174 -78 83 0 124
18 160 71 l25 38 0 277 0 278 -80 0 -80 0 0 -264 c0 -145 -3 -271 -6 -280 -8
-21 -40 -21 -48 0 -3 9 -6 135 -6 280 l0 264 -87 0 -86 0 6 -276z"/>
<path d="M1970 1360 l0 -330 70 0 70 0 1 103 c1 56 4 152 8 212 l6 110 23
-175 c13 -96 26 -192 29 -212 6 -38 6 -38 54 -38 37 0 49 4 53 18 2 9 15 103
27 209 13 105 27 189 31 187 4 -3 8 -97 8 -210 l0 -204 70 0 70 0 0 330 0 330
-104 0 c-121 0 -105 21 -132 -174 -10 -77 -20 -121 -24 -110 -4 10 -15 71 -24
134 -25 166 -11 150 -132 150 l-104 0 0 -330z"/>
<path d="M3100 1360 l0 -331 68 3 67 3 5 144 5 144 33 -114 c19 -63 38 -129
44 -146 l10 -33 74 0 74 0 0 330 0 330 -70 0 -69 0 -3 -147 -3 -148 -50 148
-49 147 -68 0 -68 0 0 -330z"/>
<path d="M3580 1361 l0 -333 153 4 c172 4 207 14 226 69 7 20 11 117 11 253 0
319 -4 325 -245 334 l-145 5 0 -332z m221 184 c9 -40 9 -299 1 -355 -6 -40
-10 -45 -34 -48 l-28 -3 0 221 0 221 27 -3 c22 -2 29 -10 34 -33z"/>
<path d="M4080 1360 l0 -330 145 0 145 0 0 65 0 65 -65 0 -65 0 0 75 0 75 55
0 55 0 0 65 0 65 -55 0 -55 0 0 60 0 60 60 0 60 0 0 65 0 65 -140 0 -140 0 0
-330z"/>
<path d="M780 930 c0 -56 3 -70 15 -70 12 0 15 14 15 70 0 56 -3 70 -15 70
-12 0 -15 -14 -15 -70z"/>
<path d="M1270 990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M1490 930 c0 -56 3 -70 15 -70 12 0 15 14 15 70 0 56 -3 70 -15 70
-12 0 -15 -14 -15 -70z"/>
<path d="M1580 930 c0 -56 3 -70 15 -70 11 0 15 12 15 50 0 28 5 50 10 50 6 0
10 -22 10 -50 0 -38 4 -50 15 -50 13 0 15 11 13 58 -3 52 -5 57 -25 56 -13 0
-23 5 -23 13 0 7 -7 13 -15 13 -12 0 -15 -14 -15 -70z"/>
<path d="M2030 990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3010 986 c0 -8 -8 -13 -17 -12 -15 1 -18 -8 -18 -54 l0 -55 38 -2
37 -3 0 70 c0 63 -2 70 -20 70 -11 0 -20 -6 -20 -14z m-3 -88 c-2 -13 -4 -3
-4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M3770 990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4080 990 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M1328 978 c-2 -7 -2 -37 0 -65 3 -41 8 -53 20 -53 14 0 17 10 15 57
-1 32 -2 61 -2 66 -2 12 -29 8 -33 -5z"/>
<path d="M4018 978 c-10 -27 7 -113 22 -116 12 -2 15 9 13 55 -1 32 -2 61 -2
66 -2 12 -29 8 -33 -5z"/>
<path d="M854 972 c-30 -20 -26 -97 6 -107 26 -8 50 4 50 26 0 24 -27 25 -33
2 -4 -14 -5 -12 -6 5 -1 16 5 22 19 22 14 0 20 7 20 24 0 30 -31 45 -56 28z"/>
<path d="M978 955 c-14 -32 1 -89 25 -93 21 -4 47 15 47 34 0 20 -28 17 -33
-3 -3 -10 -5 2 -5 27 0 25 2 37 5 28 5 -21 33 -24 33 -5 0 40 -55 49 -72 12z"/>
<path d="M1082 968 c-7 -7 -12 -18 -12 -25 0 -19 28 -16 33 5 4 16 5 16 6 0 0
-10 -8 -22 -20 -28 -13 -7 -19 -19 -17 -33 2 -17 11 -22 41 -24 36 -3 37 -3
37 31 0 71 -34 108 -68 74z m25 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z"/>
<path d="M1170 909 c0 -55 3 -69 15 -69 8 0 15 7 15 15 0 9 6 12 16 8 21 -8
34 14 34 57 0 47 -9 57 -47 58 l-33 1 0 -70z m37 -11 c-2 -13 -4 -3 -4 22 0
25 2 35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M1403 973 c-18 -7 -16 -43 1 -43 8 0 17 8 19 18 4 15 5 15 6 -1 1
-11 -8 -21 -20 -24 -15 -4 -19 -12 -17 -32 2 -21 9 -26 36 -28 l32 -3 0 54 c0
60 -16 76 -57 59z m24 -85 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M1682 923 l3 -58 38 -2 37 -2 0 59 c0 47 -3 60 -15 60 -11 0 -15 -12
-15 -50 0 -27 -4 -50 -10 -50 -5 0 -10 23 -10 50 0 38 -4 50 -15 50 -13 0 -15
-11 -13 -57z"/>
<path d="M1780 919 c0 -46 3 -59 15 -59 16 0 18 7 15 56 -1 18 3 35 9 39 7 4
11 -11 11 -44 0 -39 4 -51 15 -51 11 0 15 12 15 51 0 31 4 48 10 44 6 -3 10
-26 10 -51 0 -32 4 -44 15 -44 11 0 15 12 15 48 0 26 -4 52 -8 58 -4 5 -33 10
-64 11 l-58 0 0 -58z"/>
<path d="M1943 973 c-18 -7 -16 -43 1 -43 8 0 17 8 19 18 4 16 5 16 6 0 0 -10
-8 -22 -20 -28 -13 -7 -19 -19 -17 -33 2 -17 11 -22 41 -24 36 -2 37 -1 37 32
0 69 -24 96 -67 78z m24 -85 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M2088 920 c3 -42 8 -60 17 -60 10 0 15 17 18 53 l3 52 2 -52 c2 -40
6 -53 17 -53 13 0 15 11 13 57 l-3 58 -35 2 -35 3 3 -60z"/>
<path d="M2232 968 c-15 -15 -16 -38 -1 -38 7 0 18 8 25 18 12 15 13 15 14 1
0 -9 -11 -21 -25 -27 -43 -20 -29 -59 23 -60 l32 -1 0 54 c0 39 -4 54 -16 59
-24 9 -39 7 -52 -6z m38 -74 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11
15 -7z"/>
<path d="M2322 923 l3 -58 38 -2 37 -2 0 59 c0 47 -3 60 -15 60 -11 0 -15 -12
-15 -50 0 -27 -4 -50 -10 -50 -5 0 -10 23 -10 50 0 38 -4 50 -15 50 -13 0 -15
-11 -13 -57z"/>
<path d="M2471 966 c-7 -8 -11 -31 -9 -52 4 -44 28 -65 56 -48 23 15 28 44 8
44 -9 0 -16 -7 -16 -16 0 -8 -4 -12 -10 -9 -5 3 -10 19 -10 35 0 16 5 32 10
35 6 3 10 -1 10 -9 0 -9 7 -16 15 -16 18 0 18 5 5 31 -13 22 -43 25 -59 5z"/>
<path d="M2572 971 c-9 -5 -12 -25 -10 -57 3 -47 4 -49 33 -49 29 0 30 2 33
49 3 47 -6 66 -33 66 -5 0 -16 -4 -23 -9z m25 -73 c-2 -13 -4 -3 -4 22 0 25 2
35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M2673 973 c-8 -3 -13 -24 -13 -54 0 -43 3 -49 24 -55 31 -7 46 1 46
27 0 24 -27 25 -33 2 -4 -14 -5 -12 -6 5 -1 16 5 22 19 22 14 0 20 7 20 24 0
29 -25 42 -57 29z"/>
<path d="M2762 923 l3 -58 38 -3 37 -2 0 60 c0 53 -2 60 -19 60 -17 0 -21 -8
-24 -52 l-3 -53 -2 53 c-2 39 -6 52 -17 52 -13 0 -15 -11 -13 -57z"/>
<path d="M2860 920 c0 -47 3 -60 15 -60 11 0 15 12 15 45 0 25 5 45 10 45 22
0 8 26 -15 28 -24 2 -25 0 -25 -58z"/>
<path d="M3081 966 c-7 -8 -11 -31 -9 -52 4 -44 28 -65 56 -48 23 15 28 44 8
44 -9 0 -16 -7 -16 -15 0 -8 -4 -15 -10 -15 -5 0 -10 9 -10 20 0 15 7 20 25
20 17 0 25 5 25 18 0 38 -46 56 -69 28z m39 -16 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M3177 973 c-14 -13 -6 -49 13 -61 11 -7 20 -17 20 -23 0 -8 -5 -7
-15 1 -19 15 -30 7 -21 -15 4 -11 15 -15 33 -13 22 2 29 9 31 30 2 19 -2 28
-16 33 -21 6 -38 35 -22 35 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 18 0 19 12 3
28 -13 13 -41 16 -51 5z"/>
<path d="M3312 968 c-7 -7 -12 -31 -12 -55 0 -38 3 -43 27 -49 19 -5 30 -2 40
11 38 53 -11 137 -55 93z m38 -48 c0 -16 -4 -32 -10 -35 -6 -4 -10 10 -10 35
0 25 4 39 10 35 6 -3 10 -19 10 -35z"/>
<path d="M3400 920 c0 -47 3 -60 15 -60 11 0 15 12 15 45 0 25 5 45 10 45 22
0 8 26 -15 28 -24 2 -25 0 -25 -58z"/>
<path d="M3474 965 c-3 -7 -4 -28 -2 -47 2 -27 7 -33 26 -32 12 0 22 -5 22
-13 0 -9 -8 -11 -26 -7 -20 5 -25 3 -22 -7 3 -8 15 -15 27 -17 37 -6 51 15 51
78 l0 58 -36 0 c-20 0 -38 -6 -40 -13z m46 -29 c0 -14 -4 -28 -10 -31 -6 -4
-10 7 -10 24 0 17 5 31 10 31 6 0 10 -11 10 -24z"/>
<path d="M3583 973 c-18 -7 -16 -43 1 -43 8 0 17 8 19 18 4 16 5 16 6 0 0 -10
-8 -22 -20 -28 -13 -7 -19 -19 -17 -33 2 -17 11 -22 41 -24 36 -3 37 -3 37 31
0 70 -23 97 -67 79z m24 -85 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M3670 919 c0 -46 3 -59 15 -59 11 0 15 12 15 50 0 28 5 50 10 50 6 0
10 -22 10 -50 0 -38 4 -50 15 -50 13 0 15 11 13 57 l-3 57 -37 3 -38 2 0 -60z"/>
<path d="M3837 973 c-15 -14 -6 -51 16 -66 12 -8 18 -19 14 -23 -4 -5 -12 -2
-17 6 -11 18 -24 6 -16 -15 4 -11 15 -15 33 -13 22 2 29 9 31 30 2 19 -2 28
-16 33 -21 6 -38 35 -22 35 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 18 0 19 12 3
28 -13 13 -41 16 -51 5z"/>
<path d="M3933 973 c-18 -7 -16 -43 1 -43 8 0 17 8 19 18 4 15 5 15 6 -1 1
-11 -8 -21 -20 -24 -15 -4 -19 -12 -17 -32 2 -21 9 -26 36 -28 l32 -3 0 54 c0
60 -16 76 -57 59z m24 -85 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M4140 954 c-22 -57 -1 -101 43 -90 25 6 27 11 27 55 0 35 -4 51 -16
55 -30 11 -44 6 -54 -20z m37 -56 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2
-33 0 -45z"/>
<path d="M4240 919 c0 -45 3 -59 14 -59 11 0 16 15 19 53 l3 52 2 -52 c2 -40
6 -53 17 -53 12 0 15 13 15 59 l0 59 -35 0 -35 0 0 -59z"/>
<path d="M4342 968 c-19 -19 -14 -35 15 -56 16 -12 24 -24 19 -29 -5 -4 -11
-2 -13 5 -5 14 -33 17 -33 3 0 -15 28 -33 46 -29 9 2 22 11 28 21 9 15 6 22
-16 41 -16 12 -28 26 -28 30 0 5 7 3 15 -4 18 -15 30 -7 22 15 -7 18 -38 20
-55 3z"/>
<path d="M1270 915 c0 -42 3 -55 15 -55 12 0 15 13 15 55 0 42 -3 55 -15 55
-12 0 -15 -13 -15 -55z"/>
<path d="M2030 915 c0 -42 3 -55 15 -55 12 0 15 13 15 55 0 42 -3 55 -15 55
-12 0 -15 -13 -15 -55z"/>
<path d="M3770 915 c0 -42 3 -55 15 -55 12 0 15 13 15 55 0 42 -3 55 -15 55
-12 0 -15 -13 -15 -55z"/>
<path d="M4080 915 c0 -42 3 -55 15 -55 12 0 15 13 15 55 0 42 -3 55 -15 55
-12 0 -15 -13 -15 -55z"/>
</g>
</svg> */}
         <img src="../logo/logo33.jpeg" alt="logo"/>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/dashboard/dashboard">{logo}</RouterLink>;
}
