// import { capitalCase } from 'change-case';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Alert, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';
// routes
// hooks
// components
import Page from '../../components/Page';
import LoginForm from '../../components/LoginForm';
import { dispatch, useSelector } from '../../redux/store';
import { logout } from '../../redux/Auth/LoginSlice';
// sections
// import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const Naviagte = useNavigate()
  const {isAuthenticated}=useSelector((state)=>state.Login)
  if(isAuthenticated){
    Naviagte('/dashboard/dashboard')
  }
  useEffect(()=>{
    dispatch(logout())
  },[])
  return (
    <Page title="Login">
      <Toaster position="top-right" reverseOrder={false} />
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Professional
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
              </Box>
            </Stack>

            <Alert severity="info" sx={{ mb: 3 }}>
              Use email : <strong>professional@gmail.com</strong> / password :<strong> 12345678</strong>
            </Alert>
           
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
